.title {   
  font-family: 'Mulish', sans-serif;
  font-weight: 700;
  font-size: 32px;  
  line-height: 120%;
  margin: 0;
  /* margin-bottom: 15px; */
  text-align: center;
  max-width: 1024px;
}
  
@media (min-width: 768px) {    
  .title{   
    font-size: 40px;
  }   
}    
  
@media (min-width: 1200px) {
  .title {
    /* margin-bottom: 20px; */
  }
}
  
@media (min-width: 1920px) { 
  .title{   
    font-size: 64px;
    line-height: 140%;
  } 
}