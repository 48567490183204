.container {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 101;
  width: 100vw;
  height: 100%;
  display: flex;
  justify-content: flex-end;
  overflow: hidden;
  background-color: rgba(1, 35, 16, 0.6);
  cursor: pointer;
}

a {
  text-decoration: none;
  color:#363130;
}

.link{
  text-decoration: none;
  color: rgba(181, 0, 79, 1);
}

.link:visited{
  color: #5e002a
}

input.textarea, 
input {
  border-radius: 0;
}

.checkbox span {
  padding: 0 !important;
  font-family: 'Mulish', sans-serif;
  font-size: 14px;
  line-height: 120%;
  margin-right: 10px;
}

.closeBtn{
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  z-index: 105;
  opacity: 100 !important;
  top: 5px;
  right: 10px;
  color: #012310;
  cursor: pointer;
}

.form {
  display: none;
  position: absolute;
  top: 51%;
  left: 50%;
  position: fixed;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  width: 90%;
  align-items: center;
  background-color: #FFFF;
  padding:  30px;
  z-index: 10001 !important;
  opacity: 1 !important;
  box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.25);    
  font-family: 'Mulish', sans-serif;
  max-height: 100%;
  overflow-y: scroll;
}

.input {
  position: relative;
  width: 100%;
}

.inputWrapper {
  position: relative;
}

.label {
  background-color: #FFFF;
	position:absolute;
  z-index: 10;
	top:50%;
	left:1px;
	font-weight:500;
	padding: 0 5px;
	color:#a1a1a1;
	font-size:15px;
	letter-spacing:1px;
	-webkit-transition: all .1s ease-in-out;
  transition: all .1s ease-in-out;
  transform: translate(0, -50%);
}

.labelActive {
  background-color: #FFFF;
	position: absolute;
  /* padding: 0 5px; */
  color:#012310;
  z-index: 10;
  top: -20px;
  left: 0;
  font-size: 12px;
  font-weight: 600;
  text-transform: uppercase;
  -webkit-transition: all .1s ease-in-out;
  transition: all .1s ease-in-out;
}

:focus-visible  {
  outline-style: solid !important;
  outline-color:#0a7a3b !important;
  outline-width: 1px !important;
  box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.25);
  -webkit-box-shadow:0px 3px 10px rgba(0, 0, 0, 0.25);
	-moz-box-shadow:0px 3px 10px rgba(0, 0, 0, 0.25);
}

.input.mess,
.input.mess{
  grid-column:  1 / -1;
}

.list_input{
  display: grid;
  gap: 30px;
}

.formTitle{
  font-weight: 600; 
  font-size: 28px;
  line-height: 120%; 
  color: #012310;
  text-align: center;
  margin-bottom: 10px;
}

.formText{
  margin-bottom: 35px;
  font-weight: 500;
  font-size: 16px;
  line-height: 140%;
  color: #012310;;
  text-align: center;
}

.form input{
  width: 100%;
  background-color: #FFFF;
  border: 1px solid #cccccc;
  padding: 10px 10px;

}

.form input::placeholder{
  font-family: 'Mulish', sans-serif;
  font-style: normal;
  font-size: 15px;
  font-weight: 500;
  color: #b81616;
  top: calc(50% - 20px/2);
}

input.error{
  border:1px solid rgba(181, 0, 79, 1);
  color: rgba(181, 0, 79, 1);
}

.phoneInput {
  background-color: #FFFF !important;
  border: 1px solid #cccccc !important;
}

.errorInput {
  border:1px solid rgba(181, 0, 79, 1) !important;
  color: rgba(181, 0, 79, 1) !important;
}

.errortext{
  position:absolute;
  left: 0;
  bottom: -12px;
  font-family: 'Mulish', sans-serif;
  font-style: normal;
  font-size: 10px;
  line-height: 10px;
  color: rgba(181, 0, 79, 1);
  z-index: 105;  
}

.textarea{
  border: none;
  width: 100%;
  height: 60px;
  border-radius: 4px;
  background-color: #FFFF;
  border: none; 
  border-radius: 4px;
  resize: none; 
  margin-top: 5px;
  padding:  5px 10px;  
}

.checkbox{
  background-color: #FFFF;
  grid-column:  1 / -1;
  color: #363130;
  font-family: 'Mulish', sans-serif;
  font-style: normal;
  font-weight: 400;
  text-align: left;
  margin-left: 0 !important;
  margin-right: 0 !important;
}

[data-testId="CheckBoxIcon"] {
  color: #0a7a3b;
}

.textarea::placeholder{
  font-family: 'Mulish', sans-serif;
  font-style: normal;
  font-size: 15px;
  font-weight: 500;
  color: #a1a1a1;
}

.btn{
  margin-top: 40px;
} 

.btn::placeholder{
  background-color: rgba(157, 0, 69, 1);
  border-style: none;
  color: #E6FEF0;
}

.btn:hover{
  background-color: rgba(181, 0, 79, 1);
  cursor: pointer;
}
  
@media (min-width: 520px) {
  .form{
    max-width: 600px;
  }

  .formText{
    line-height: 140%;
    width: 90%;
  }

  .list_input{
    grid-template-columns: 1fr 1fr;
  }
}

@media (min-width: 1200px){
  .form{
    max-width: 700px;
    max-height: 800px;
    padding: 40px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    overflow: hidden;
  }

  .errortext{    
    font-size: 12px;    
  }  

  .formTitle {
    font-size: 28px;
    margin-bottom: 0px;
    line-height: 140%;
  }

  .formText{
    font-size: 18px;      
  }
}

@media (min-width: 1920px){
  .form{
    max-width: 950px;  
    padding: 50px;
  }

  .formTitle {
    font-size: 40px;       
  }

  .formText{ 
    margin-bottom: 40px;
    font-size: 20px;      
  }

  .form input,
  .textarea {
    font-size: 20px;
  }

  .closeBtn{
    font-size: 25px;
  }

  .btnGreen {
    margin-top: 60px;
    font-size: 20px;
    padding: 15px 25px;
  }
}

@media (min-width: 2560px){
  .form{
    max-width: 750px;
    padding: 70px;
  }

  .formTitle {
    font-size: 30px;  
  }

  .formText{ 
    margin-bottom: 50px;
  }

  .form input,
  .textarea {
    font-size: 24px;
  }

  .closeBtn{
    font-size: 30px;
    top: 10px;
    right: 15px;
  }  
}